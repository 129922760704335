module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Allianz Foundation","short_name":"Allianz Foundation","description":"Allianz Kulturstiftung and Allianz Umweltstiftung have joined forces and become Allianz Foundation. Building upon a rich legacy, the Allianz Foundation will pursue its goals while responding to the demands of our times: enabling better living conditions for current and future generations. We support initiatives, people and organizations from civil society, the environment and culture and the arts - partners who are committed to social justice, open societies and a livable planet. We have a particular focus on projects working at the interface of these areas and aiming for systemic change. Regular calls on specific topic areas are published on our website.","lang":"en","display":"standalone","icon":"./src/assets/icon.png","start_url":"/","background_color":"#000","theme_color":"#fff","localize":[{"name":"Allianz Foundation","short_name":"Allianz Foundation","start_url":"/de/","lang":"de","description":"Wir alle leben in einer Gegenwart, die von schnellen Veränderungen geprägt ist. Eine Gegenwart, die die nächsten Generationen vor große Herausforderungen stellt."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6ad853b3654c19671ac8975e19918bbe"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://allianz-foundation.de/matomo/","siteUrl":"https://allianzfoundation.org/","disableCookies":true,"dev":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
