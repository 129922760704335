import './src/styles/global.css'
import './src/styles/manual.css'
import "./src/assets/webfonts.css"
import "./src/assets/plyr.css"

const onRouteUpdate = () => {
  // Scroll to the top of the page on route update
  window.scrollTo(0, 0);
};

export { onRouteUpdate };
